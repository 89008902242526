$start1: #FF564E
$start2: #11E54D
$start3: #00ABD8
$start4: #DD0093
$textbox: #d6d0c7
$spalte: 96px
$texthell: #9a968f
$text: rgb(64, 64, 64)

=bp($size)
  @if $size == mega
    @media (max-width: 1080px)
      @content

  @if $size == maxi
    @media (max-width: 870px)
      @content

  @if $size == midi
    @media (max-width: 700px)
      @content

  @if $size == mini
    @media (max-width: 540px)
      @content

// body:before
//   content: "standard"
//   position: fixed
//   background: fuchsia
//   color: white
//   left: 0
//   top: 0
//   padding: 15px 20px
//   z-index: 9999
//   font-weight: bold
//   display: block
// 
// body:before
//   +bp(mega)
//     content: "mega"
//     background: yellow
//     display: block
// 
// body:before
//   +bp(maxi)
//     content: "maxi"
//     background: orange
// 
// body:before
//   +bp(midi)
//     content: "midi"
//     background: black
// 
// body:before
//   +bp(mini)
//     content: "mini"
//     background: cyan

body,
html
  height: 100%

*
  margin: 0
  padding: 0
  font-family: "allumi-std"
  font-weight: normal
  font-style: normal
  color: $text
  line-height: 1.35em
  border: none
  outline: none
  font-size: 30px
  +bp(mega)
    font-size: 27px
  +bp(maxi)
    font-size: 21px
  +bp(midi)
    font-size: 18px
  +bp(mini)
    font-size: 15px

body
  background: white

a
  text-decoration: none

.links
  color: $texthell
  cursor: pointer

ul
  list-style: none

address
  font-style: normal


h2,
ul
  margin-bottom: 0.7em

.address
  margin-top: 1.35em
  opacity: 0
strong
  font-weight: bold
  
// ==========================================

.startfarbe, .h1box
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 2
  // opacity: 0.1
  border: solid white 0.72em
  box-sizing: border-box
  padding-top: 3.2em

.h1box
  background: white
  z-index: 1

h1
  position: relative
  left: 1.3em
  span
    position: relative
    top: -0.415em
    display: block
    // outline: 1px dotted red
    font-weight: bold
    font-size: 13em
    float: left
  em
    display: inline
    margin-right: 2em
    // outline: 1px dotted cyan
    position: relative
    left: -0.2em
    
.startfarbe1
  background-color: $start1
  span
    color: lighten($start1, 4%)

.startfarbe2
  background-color: $start2
  span
    color: lighten($start2, 4%)

.startfarbe3
  background-color: $start3
  span
    color: lighten($start3, 4%)

.startfarbe4
  background-color: $start4
  span
    color: lighten($start4, 4%)
    


.layout
  border-top: 0.75em solid white
  max-width: 800px
  padding: 3.2em 5em 0 10.3em

  +bp(mini)
    padding: 3.2em 3em 0 5.3em

